// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_CUSTOMER_AUTH = '/customer';
const ROOTS_CUSTOMER = '/customer/panel';

// ----------------------------------------------------------------------
export const PATH_AUTH = {}
export const PATH_CUSTOMER_AUTH = {
  root: ROOTS_CUSTOMER_AUTH,
  login: path(ROOTS_CUSTOMER_AUTH, '/login'),
  register: path(ROOTS_CUSTOMER_AUTH, '/register'),
  resetPassword: path(ROOTS_CUSTOMER_AUTH, '/reset-password'),
  newPassword: path(ROOTS_CUSTOMER_AUTH, '/new-password'),
  verify: path(ROOTS_CUSTOMER_AUTH, '/verify')
};


export const PATH_CUSTOMER = {
  root: ROOTS_CUSTOMER,
  general: {
    dashboard: path(ROOTS_CUSTOMER, '/dashboard'),
    location: path(ROOTS_CUSTOMER, '/location'),
  },
  ourcustomer: {
    root: path(ROOTS_CUSTOMER, '/ourcustomer'),
    companyinfo: path(ROOTS_CUSTOMER, '/ourcustomer/companyinfo'),
    usercontactinfo: path(ROOTS_CUSTOMER, '/ourcustomer/usercontactinfo'),
    locationinfo: path(ROOTS_CUSTOMER, '/ourcustomer/locationinfo'),
    elevatorinfo: path(ROOTS_CUSTOMER, '/ourcustomer/elevatorinfo'),
    fireinfo: path(ROOTS_CUSTOMER, '/ourcustomer/fireinfo'),
    keylogsinfo: path(ROOTS_CUSTOMER, '/ourcustomer/keylogsinfo'),
    firealarmasset: path(ROOTS_CUSTOMER, '/ourcustomer/firealarmasset'),
    wetsprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/wetsprinkler'),
    drysprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/drysprinkler'),
    preactionsprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/preactionsprinkler'),
    delugesprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/delugesprinkler'),
    standpipe: path(ROOTS_CUSTOMER, '/ourcustomer/standpipe'),
    firepump: path(ROOTS_CUSTOMER, '/ourcustomer/firepump'),
    firehydrant: path(ROOTS_CUSTOMER, '/ourcustomer/firehydrant'),
    kitchenhood: path(ROOTS_CUSTOMER, '/ourcustomer/kitchenhood'),
    drychemical: path(ROOTS_CUSTOMER, '/ourcustomer/drychemical'),
    cleanagent: path(ROOTS_CUSTOMER, '/ourcustomer/cleanagent'),
    fireescape: path(ROOTS_CUSTOMER, '/ourcustomer/fireescape'),
    fireextinguisher: path(ROOTS_CUSTOMER, '/ourcustomer/fireextinguisher'),
    backflow: path(ROOTS_CUSTOMER, '/ourcustomer/backflow'),
    othersprinkler: path(ROOTS_CUSTOMER, '/ourcustomer/othersprinkler'),
    companylocationquantity: path(ROOTS_CUSTOMER, '/ourcustomer/companylocationquantity'),
    myassets: path(ROOTS_CUSTOMER, '/myassets'),
    mytask: path(ROOTS_CUSTOMER, '/mytask'),
    contractor: path(ROOTS_CUSTOMER, '/contractor'),
    deficiencyentryform: path(ROOTS_CUSTOMER, '/ourcustomer/deficiencyentryform'),
    Jobentryform: path(ROOTS_CUSTOMER, '/ourcustomer/Job'),
    customerlocation: path(ROOTS_CUSTOMER, '/ourcustomer/customerlocation'),
    viewlocation: path(ROOTS_CUSTOMER, '/ourcustomer/viewlocation/:id'),
    locationassetinventory: path(ROOTS_CUSTOMER, '/ourcustomer/locationassetinventory'),
    serviceentryform: path(ROOTS_CUSTOMER, '/ourcustomer/services'),
  },
  ourtask: {
    root: path(ROOTS_CUSTOMER, '/ourtask'),
    saleslead: path(ROOTS_CUSTOMER, '/ourtask/saleslead'),
    onboarding: path(ROOTS_CUSTOMER, '/ourtask/onboarding'),
    reviewdataupdate: path(ROOTS_CUSTOMER, '/ourtask/reviewdataupdate'),
    reviewupload: path(ROOTS_CUSTOMER, '/ourtask/reviewupload'),
    scheduleservice: path(ROOTS_CUSTOMER, '/ourtask/scheduleservice'),

  },
  uploaddocuments: {
    root: path(ROOTS_CUSTOMER, '/uploaddocuments'),
    uploaddocument: path(ROOTS_CUSTOMER, '/uploaddocuments/uploaddocument'),
    editdocument: path(ROOTS_CUSTOMER, '/uploaddocuments/editdocument/:id'),

  }
};

