import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import CustomerLayout from '../layouts/customer';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_CUSTOMER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <>
      <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard/dashboard')} />}>
        <Component {...props} />
      </Suspense>
    </>
  );
};

export default function Router() {
  return useRoutes([
       {
          path: '/',
          element: (
            <GuestGuard>
              <CustomerLogin />
            </GuestGuard>
          ),
        },
    // Customer Auth
    {
      path: 'customer',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <CustomerLogin />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        // Customer Routes
        {
          path: 'panel',
          element: (
            <AuthGuard>
              <CustomerLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_CUSTOMER_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <CustomerDashboard /> },
            {
              path: 'ourcustomer',
              children: [
                { element: <Navigate to="/customer/ourcustomer/companyinfo" replace />, index: true },
                { path: 'companyinfo', element: <CustomerCompanyInfo /> },
                { path: 'usercontactinfo', element: <UserContactInfo /> },
                { path: 'locationinfo', element: <LocationInfo /> },
                { path: 'elevatorinfo', element: <CustomerElevator /> },
                { path: 'fireinfo', element: <CustomerFireDep /> },
                { path: 'keylogsinfo', element: <CustomerKeyLogs /> },
                { path: 'firealarmasset', element: <CustomerFireAlarmAsset /> },
                { path: 'wetsprinkler', element: <CustomerWetsprinkler /> },
                { path: 'drysprinkler', element: <DrySprinkler /> },
                { path: 'preactionsprinkler', element: <Preaction /> },
                { path: 'delugesprinkler', element: <Deluge /> },
                { path: 'standpipe', element: <Standpipe /> },
                { path: 'firepump', element: <Firepumpsprinkler /> },
                { path: 'FireHydrant', element: <FireHydrant /> },
                { path: 'kitchenhood', element: <KitchenHoodSprinkler /> },
                { path: 'drychemical', element: <DryChemical /> },
                { path: 'cleanagent', element: <CleanAgent /> },
                { path: 'companylocationquantity', element: <CompanyLocationQuantity /> },
                { path: 'fireescape', element: <FireEscape /> },
                { path: 'fireextinguisher', element: <FireExtinguisher /> },
                { path: 'backflow', element: <Backflow /> },
                { path: 'othersprinkler', element: <OtherSprinkler /> },
                { path: 'deficiencyentryform', element: <DeficiencyEntryForm /> },
                { path: 'Job', element: <JobEntryForm /> },
                { path: 'Job/update/:formid', element: <UpdateJobEntryForm /> },
                { path: 'customerlocation', element: <CustomerLocationTable /> },
                { path: 'viewlocation/:id', element: <ViewLocation /> },
                { path: 'locationassetinventory', element: <LocationAssetInventory /> },
                { path: 'services', element: <ServiceEntryForm /> },
                { path: 'services/update/:formID', element: <UpdateServiceEntryForm /> },
                { path: 'elevatorTask', element: <ElevatorTask /> },
                { path: 'fireDepartmentTask', element: <FireDepartmentTask /> },
                { path: 'fireAlarmTask', element: <FireAlarmTask /> },
                { path: 'wetSprinklerTask', element: <WetSprinklerTask /> },
                { path: 'drySprinklerTask', element: <DrySprinklerTask /> },
                { path: 'preActionTask', element: <PreActionTask /> },
                { path: 'delugeTask', element: <DelugeTask /> },
                { path: 'standpipeTask', element: <StandpipeTask /> },
                { path: 'firePumpTask', element: <FirePumpTask /> },
                { path: 'kitchenHoodTask', element: <KitchenHoodTask /> },
                { path: 'dryChemicalTask', element: <DryChemicalTask /> },
                { path: 'CleanAgentTask', element: <CleanAgentTask /> },
                { path: 'fireEscapeTask', element: <FireEscapeTask /> },
                { path: 'backFlowTask', element: <BackFlowTask /> },
                { path: 'otherLocationTask', element: <OtherLocationTask /> },
                { path: 'elevatorassets', element: <ElevatorAsset /> },
                { path: 'firedepartmentassets', element: <FireDepartmentAsset /> },
                { path: 'firealarmassets', element: <FireAlarmAsset /> },
                { path: 'wetsprinklerassets', element: <WetSprinklerAsset /> },
                { path: 'drysprinklerassets', element: <DrySprinklerAsset /> },
                { path: 'preactionassets', element: <PreActionAsset /> },
                { path: 'delugeassets', element: <DelugeAsset /> },
                { path: 'standpipeassets', element: <StandpipeAsset /> },
                { path: 'firepumpassets', element: <FirePumpAsset/> },
                { path: 'kitchenhoodassets', element: <KitchenHoodAsset /> },
                { path: 'drychemicalassets', element: <DryChemicalAsset /> },
                { path: 'cleanagentassets', element: <CleanAgentAsset /> },
                { path: 'fireescapeassets', element: <FireEscapeAsset /> },
                { path: 'backflowassets', element: <BackFlowAsset /> },
                { path: 'otherlocationassets', element: <OtherLocationAsset /> },
              ]
            },
            { path: 'location', element: <CustomerLocation /> },
            { path: 'uploaddocuments', element: <CustomerUploadDocuments /> },
            { path: 'uploaddocuments/uploaddocument', element: <UploadDocument /> },
            { path: 'uploaddocuments/editdocument/:id', element: <EditDocument /> },
            {
              path: 'ourtask',
              children: [
                { element: <Navigate to="/customer/ourtask/saleslead" replace />, index: true },
                { path: 'saleslead', element: <CustomerSalesleads /> },
                { path: 'onboarding', element: <CustomerOnboardings /> },
                { path: 'reviewdataupdate', element: <CustomerReviewdataupdates /> },
                { path: 'reviewupload', element: <CustomerReviewuploads /> },
                { path: 'scheduleservice', element: <CustomerScheduleservices /> },

              ],
            },
            {
              path: 'myassets', element: <MyAssets />
            },
            {
              path: 'mytask', element: <MyTask />
            },
            {
              path: 'contractor', element: <Contractor />
              // path: 'deficiency', element: <CuDeficiencyEntryForm />
            },
          ],
        },
      ],
    },


    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//  Authentication
const CustomerLogin = Loadable(lazy(() => import('../pages/auth/CustomerLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/CustomerRegister')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/CustomerResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/CustomerNewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/CustomerVerifyCode')));
// Customer
const CustomerDashboard = Loadable(lazy(() => import('../pages/customer/Dashboard')));
const CustomerCompanyInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/CustomerCompanyInfo')));
const UserContactInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/UserContactInfo')));
const LocationInfo = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationInfo')));
const UploadDocument = Loadable(lazy(() => import('../pages/customer/ourcompany/UploadDocument')));
const EditDocument = Loadable(lazy(() => import('../pages/customer/ourcompany/EditDocument')));
const DeficiencyEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/DeficiencyEntryForm')));
const JobEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/JobEntryForm')));
const UpdateJobEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/Job/UpdateJobFrom')));
const CustomerLocationTable = Loadable(lazy(() => import('../pages/customer/ourcompany/CustomerLocationTable')));
const CustomerUploadDocuments = Loadable(lazy(() => import('../pages/customer/ourcompany/CustomerUploadDocuments')));
const ViewLocation = Loadable(lazy(() => import('../pages/customer/ourcompany/ViewLocation')));
const LocationAssetInventory = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetInventory')));
const ServiceEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/ServiceEntryForm')));
const UpdateServiceEntryForm = Loadable(lazy(() => import('../pages/customer/ourcompany/Service/UpdateEntryFrom')));
const CustomerLocation = Loadable(lazy(() => import('../pages/customer/Location')));
const CustomerElevator = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/Elevator')));
const CustomerFireDep = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/FireDeparte')));
const CustomerKeyLogs = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationDetailForm/KeyLogs')));
const CustomerFireAlarmAsset = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireAlarmAsset')));
const CustomerWetsprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Wetsprinkler')));
const DrySprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DrySprinkler')));
const Preaction = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Preaction')));
const Deluge = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DelugeSprinkler')));
const Standpipe = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/StandpipeSprinkler')));
const Firepumpsprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/Firepumpsprinkler')));
const FireHydrant = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireHydrantsprinkler')));
const KitchenHoodSprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/KitchenHoodSprinkler')));
const DryChemical = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/DryChemicalSprinkler')));
const CleanAgent = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/CleanAgentSprinkler')));
const FireEscape = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/EscapeSprinkler')));
const FireExtinguisher = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/FireExtinguisherSprinkler')));
const Backflow = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/BackflowSprinkler')));
const OtherSprinkler = Loadable(lazy(() => import('../pages/customer/ourcompany/LocationAssetForm/OtherSprinkler')));
const CompanyLocationQuantity = Loadable(lazy(() => import('../pages/customer/ourcompany/CompanyLocationQuantity')));
const CustomerSalesleads = Loadable(lazy(() => import('../pages/customer/Salesleads')));
const CustomerOnboardings = Loadable(lazy(() => import('../pages/customer/Onboardings')));
const CustomerReviewdataupdates = Loadable(lazy(() => import('../pages/customer/Reviewdataupdates')));
const CustomerReviewuploads = Loadable(lazy(() => import('../pages/customer/Reviewuploads')));
const CustomerScheduleservices = Loadable(lazy(() => import('../pages/customer/Scheduleservices')));
const MyAssets = Loadable(lazy(() => import('../pages/customer/ourcompany/MyAssets')));
const MyTask = Loadable(lazy(() => import('../pages/customer/ourcompany/MyTask')));
const ElevatorTask = Loadable(lazy(() => import('../sections/@Mytask/ElevatorTask')));
const FireDepartmentTask = Loadable(lazy(() => import('../sections/@Mytask/FireDepartmentTask')));
const FireAlarmTask = Loadable(lazy(() => import('../sections/@Mytask/FireAlarmTask')));
const WetSprinklerTask = Loadable(lazy(() => import('../sections/@Mytask/WetSprinklerTask')));
const DrySprinklerTask = Loadable(lazy(() => import('../sections/@Mytask/DrySprinklerTask')));
const PreActionTask = Loadable(lazy(() => import('../sections/@Mytask/PreActionTask')));
const DelugeTask = Loadable(lazy(() => import('../sections/@Mytask/DelugeTask')));
const StandpipeTask = Loadable(lazy(() => import('../sections/@Mytask/StandpipeTask')));
const FirePumpTask = Loadable(lazy(() => import('../sections/@Mytask/FirePumpTask')));
const KitchenHoodTask = Loadable(lazy(() => import('../sections/@Mytask/KitchenHoodTask')));
const DryChemicalTask = Loadable(lazy(() => import('../sections/@Mytask/DryChemicalTask')));
const CleanAgentTask = Loadable(lazy(() => import('../sections/@Mytask/CleanAgentTask')));
const FireEscapeTask = Loadable(lazy(() => import('../sections/@Mytask/FireEscapeTask')));
const BackFlowTask = Loadable(lazy(() => import('../sections/@Mytask/BackFlowTask')));
const OtherLocationTask = Loadable(lazy(() => import('../sections/@Mytask/OtherLocationTask')));
const ElevatorAsset = Loadable(lazy(() => import('../sections/@Myasset/ElevatorAsset')));
const FireDepartmentAsset = Loadable(lazy(() => import('../sections/@Myasset/FireDepartmentAsset')));
const FireAlarmAsset = Loadable(lazy(() => import('../sections/@Myasset/FireAlarmAsset')));
const WetSprinklerAsset = Loadable(lazy(() => import('../sections/@Myasset/WetSprinklerAsset')));
const DrySprinklerAsset = Loadable(lazy(() => import('../sections/@Myasset/DrySprinklerAsset')));
const PreActionAsset = Loadable(lazy(() => import('../sections/@Myasset/PreActionAsset')));
const DelugeAsset = Loadable(lazy(() => import('../sections/@Myasset/DelugeAsset')));
const StandpipeAsset = Loadable(lazy(() => import('../sections/@Myasset/StandpipeAsset')));
const FirePumpAsset = Loadable(lazy(() => import('../sections/@Myasset/FirePumpAsset')));
const KitchenHoodAsset = Loadable(lazy(() => import('../sections/@Myasset/KitchenHoodAsset')));
const DryChemicalAsset = Loadable(lazy(() => import('../sections/@Myasset/DryChemicalAsset')));
const CleanAgentAsset = Loadable(lazy(() => import('../sections/@Myasset/CleanAgentAsset')));
const FireEscapeAsset = Loadable(lazy(() => import('../sections/@Myasset/FireEscapeAsset')));
const BackFlowAsset = Loadable(lazy(() => import('../sections/@Myasset/BackFlowAsset')));
const OtherLocationAsset = Loadable(lazy(() => import('../sections/@Myasset/OtherLocationAsset')));
const Contractor = Loadable(lazy(() => import('../pages/customer/ourcompany/Contractor')));
// const CuDeficiencyEntryForm = Loadable(lazy(() => import('../sections/@Customer/CuDeficiencyEntryForm')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
