// routes
import { PATH_CUSTOMER } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  company: getIcon('ic_company'),
  contact: getIcon('ic_contact'),
  location: getIcon('ic_location'),
  credential: getIcon('ic_credential'),
  asset: getIcon('ic_asset'),
  feedback: getIcon('ic_feedback'),
  task: getIcon('ic_ourtask'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_CUSTOMER.general.dashboard, icon: ICONS.dashboard },
      {
        title: 'Our Company',
        path: PATH_CUSTOMER.ourcustomer.root,
        icon: ICONS.user,
        children: [
          { title: 'Company Info', path: PATH_CUSTOMER.ourcustomer.companyinfo },
          { title: 'User Contact Info', path: PATH_CUSTOMER.ourcustomer.usercontactinfo },
          { title: 'Location Info', path: PATH_CUSTOMER.ourcustomer.customerlocation },
          { title: 'Services', path: PATH_CUSTOMER.ourcustomer.serviceentryform },
          { title: 'Job', path: PATH_CUSTOMER.ourcustomer.Jobentryform },
          { title: 'My Assets', path: PATH_CUSTOMER.ourcustomer.myassets },
          { title: 'My Task', path: PATH_CUSTOMER.ourcustomer.mytask },
          { title: 'My Contractor', path: PATH_CUSTOMER.ourcustomer.contractor },
          // { title: 'Company Location Quantity', path: PATH_CUSTOMER.ourcustomer.companylocationquantity },
          // { title: 'Deficiencies', path: PATH_CUSTOMER.ourcustomer.deficiencyentryform },
        ]
      },
      {
        title: 'Our Task',
        path: PATH_CUSTOMER.ourtask.root,
        icon: ICONS.task,
        children: [
          { title: 'Our Sales Lead', path: PATH_CUSTOMER.ourtask.saleslead },
          { title: 'On Boarding', path: PATH_CUSTOMER.ourtask.onboarding },
          { title: 'Review Data Update', path: PATH_CUSTOMER.ourtask.reviewdataupdate },
          { title: 'Review Upload', path: PATH_CUSTOMER.ourtask.reviewupload },
          { title: 'Schedule Service', path: PATH_CUSTOMER.ourtask.scheduleservice },
        ],
      },
      { title: 'Upload Documents', path: PATH_CUSTOMER.uploaddocuments.root, icon: ICONS.booking },
    ],
  },
]
export default navConfig;
